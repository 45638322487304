import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card} from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios'
import { Link } from 'react-router-dom';
import CategoryLoading from '../PlaceHolder/CategoryLoading';

class Categories extends Component {
     constructor(){
          super();
          this.state ={
               MenuData:[],
               isLoading:"",
               mainDiv:"d-none"
          }
     }

     componentDidMount(){
          // axios.get(AppURL.AllCategoryDetails).then(response =>{ 
          //       this.setState({MenuData:response.data,isLoading:"d-none",
          //       mainDiv:" "});

          // }).catch(error=>{

          // });
     }

     render() {
          let isLoading = this.props.isLoading; 
          return (
               <div className={isLoading}>

<Container className="text-center" fluid={true}>
          <div className="section-title text-center mb-55"><h2>Welcome Back!</h2>
  
          <a href={"./productcategory/"+localStorage.getItem('customerno')}><p>{localStorage.getItem('customerno')}
          / {localStorage.getItem('company')}
          </p>
          <p className="btn btn-sm"><i className="fa fa-shopping-cart"></i> *Aug23</p>
          </a>
          </div>
               
{/* 
<div className="row">
<div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>



          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>



          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>



          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>



          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>



          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>


          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>


          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>


          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>


          <div className="col-lg-2 col-md-2 col-sm-4 col-6 p-1">
               <a href="" className="card image-box h-100  w-100">
                    <div class="ph-picture"></div>
                    <div className="ph-item">
                         <div className="ph-col-12">
                         <div className="ph-row">
                              <div className="ph-col-12 small"/>
                               
                         </div>
                         </div>
                    </div>
               </a>
          </div>
                                  
               
                              </div>
                          // end row div */}
                              </Container>
                              </div>
          )
     }
}

export default Categories
