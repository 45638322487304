import React, { Component } from 'react'
import { Fragment } from 'react'
import {Container,Row,Col,Card, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import cogoToast from 'cogo-toast';
import AppURL from '../../api/AppURL'
import axios from 'axios'

class Category extends Component {
     
     state = {
          QEquantity: 1
     }// state creat

     addToCartTest = (f,e) => {
          // console.log('click product_code: ')
          console.log(f);
     }
     addToCart = (f,s,e) => {

          let isSize = '';
          let isColor = '';
          let color = '';
          let size = s;
     
          let quantity = 1;

          if (this.state.QEquantity > 0 ) {
               quantity = this.state.QEquantity;
          }
     
          let productCode = f;
          let category = this.props.Category;
          let email = localStorage.getItem('email');
     
          // console.log(size);
          //this.props.user.email;

          if(isColor==="NO" && color.length===0){
               cogoToast.error('Please Select Color',{position:'top-right'});
          }
          else if(isSize==="NO" && size.length===0){
               cogoToast.error('Please Select Size',{position:'top-right'});
          } 
          else if(quantity.length===0){
               cogoToast.error('Please Select Quantity',{position:'top-right'});
          }
          else if (!localStorage.getItem('token')){
               cogoToast.warn('Please You have to Login First',{position:'top-right'});
          }
          else{
               this.setState({addToCart:"Adding..."})
               let MyFormData = new FormData();
               MyFormData.append("color",color);
               MyFormData.append("size",size);
               MyFormData.append("quantity",quantity);
               MyFormData.append("product_code",productCode);
               MyFormData.append("email",email);
//*Sun 2022-08-25 13:39:22 add to cart inclued category
               MyFormData.append("category",category);
               
               axios.post(AppURL.addToCart,MyFormData).then(response =>{
                    if(response.data===1){
                         cogoToast.success("Product Added Successfully",{position:'bottom-center'});
                         this.setState({addToCart:"Add To Cart"})
                         this.setState({PageRefreshStatus:true})    
                         
                         axios.get(AppURL.CartCount(email)).then((response)=>{
                              this.setState({cartCount:response.data}) ;
                         
                              const json = JSON.stringify({cartCount:response.data}) ;
                              const obj = JSON.parse(json);
                              // console.log(obj.cartCount);
                              cogoToast.success("Total Order Items : " + obj.cartCount  ,{position:'bottom-center'});              
                         })                         
                    }
                    else{
                         cogoToast.error("Your Request is not done ! Try Aagain",{position:'top-right'});
                         this.setState({addToCart:"Add To Cart"})
                    }
                             
               }).catch(error=>{
                    cogoToast.error("Your Request is not done ! Try Aagain",{position:'top-right'});
                         this.setState({addToCart:"Add To Cart"})
     
               });

               // *SUN Reset Quantity as 1 after add to cart

          this.setState({QEquantity:1});

          }          


     }  /// End addToCart Mehtod 

     handleChange = event => {
          const result = event.target.value.replace(/\D/g, '');
          this.setState({ QEquantity: result });
     };

     render() {

          const MyList = this.props.ProductData;
          //console.log(MyList);
          const Category = this.props.Category;
          const MyView = MyList.map((ProductList,i)=>{

          if(ProductList.special_price=="na"){
               return   <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
               <Link className="text-link" to={"/productdetails/"+ProductList.id} >
               <Card className="image-box card w-100">
               <img className="center w-75" src={ProductList.image} />   
               <Card.Body> 
               <p className="product-name-on-card">{ProductList.title}</p>
               <p className="product-price-on-card">Item #: {ProductList.product_code}<br></br>Price : ${ProductList.price}</p>
               </Card.Body>
               </Card>  
               </Link> 
               <Card className="image-box card w-100">
               {/* *Sun 08/06/22 - star = size passing through add-to-cart */}
               <Card.Body> 
               
               <p className="product-name-on-card"><input id={i} type="number"   style={{ width: "100px" }} pattern="[0-9]*" placeholder='1' value={this.value} onChange={this.handleChange}/></p>     
               <Button  onClick={(e) => this.addToCart(ProductList.product_code, ProductList.star, e)}>Add to Cart
               </Button>
               </Card.Body>
               </Card>  
               </Col>

          }
          else{

               return   <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                     <Link className="text-link" to={"/productdetails/"+ProductList.id} >
               <Card className="image-box card w-100">
               <img className="center w-75" src={ProductList.image}abc />   
               <Card.Body> 
               <p className="product-name-on-card">{ProductList.title}</p>
               <p className="product-price-on-card">Price : <strike className="text-secondary">${ProductList.price}</strike> ${ProductList.special_price}</p>
                    
               </Card.Body>
               </Card>   
               </Link>       
               </Col>

          } 
 
          });



          return (
               <Fragment>
               <Container className="text-center" fluid={true}>
               
               <div className="breadbody">
               <Breadcrumb>
  <Breadcrumb.Item> <Link to="/"> Home </Link> </Breadcrumb.Item>
  <Breadcrumb.Item> <Link to={"/productcategory/"+Category}> {Category } </Link> </Breadcrumb.Item>   
</Breadcrumb>
</div>


      <div className="section-title text-center mb-40 mt-2"><h2> {Category} / { localStorage.getItem('company') }  </h2>
      
      </div>

 <Row> 
           {MyView}
           
 </Row>
               </Container>
          </Fragment>
          )
     }
}

export default Category
