import React, { Component, Fragment } from "react";

import AppURL from "../../api/AppURL";
import axios from "axios";
import { Link } from "react-router-dom";

class MegaMenuMobile extends Component {
  constructor() {
    super();
    this.state = {
      MenuData: [],
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.AllCategoryDetails)
      .then((response) => {
        this.setState({ MenuData: response.data });
      })
      .catch((error) => {});
  }

  MenuItemClick = (event) => {
    event.target.classList.toggle("active");
    var panel = event.target.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  };

  render() {
    const CatList = this.state.MenuData;

    const MyView = CatList.map((CatList, i) => {
      if (CatList.category_name == localStorage.getItem("customerno")) {
        return (
          <div key={i.toString()}>
            <button onClick={this.MenuItemClick} ClassName="accordionAll">
              {/* <img
                ClassName="accordionMenuIconMobile"
                src={CatList.category_image}
              /> */}
              &nbsp; {CatList.category_name}
            </button>
            <div ClassName="panelAll">
              <ul>
                {CatList.subcategory_name.map((SubList, i) => {
                  return (
                    <li>
                      <Link
                        to={
                          "/productsubcategory/" +
                          CatList.category_name +
                          "/" +
                          SubList.subcategory_name
                        }
                        ClassName="accordionItem"
                      >
                        {SubList.subcategory_name}{" "}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        );
      }
    });

    return (
      <div ClassName="accordionMenuDivMobile">
        <div ClassName="accordionMenuDivInsideMobile">{MyView}</div>
      </div>
    );
  }
}

export default MegaMenuMobile;
