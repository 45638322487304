import React, { Component, Fragment } from 'react'
import {BrowserRouter} from 'react-router-dom';
import AppRoute from './route/AppRoute';

// *Sun 2022-08-11 13:08:32 Added ServiceWorker Part
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import AppUpdateAlert from './components/appUpdate/Component';
 
const UPDATE_AVAILABLE_MESSAGE = "New content is available and will be automatically installed momentarily.";
const SUCCESSFUL_INSTALL_MESSAGE = "This app has successfully updated itself in the background. Content is cached for offline use.";
 

class App extends Component {
 
  constructor(props) {
    super(props);
 
    this.state = {
      isSet: false,              // True once componentDidMount runs
      customClass: props.cls,    // Expects "online" or "offline"
 
      // service worker state management
      // -----------------------------------------------------------------------------------------
      updatedSW: null,             // The service worker that is waiting to be updated
 
      isSWUpdateAvailable: false,  // Set to True to trigger a Bootstrap alert.
                                   // Set from a Workbox callback 
                                   // after a new service worker has 
                                   // downloaded and if ready to install.
 
      wasSWInstalledSuccessfully: false    // Set to True to trigger a Bootstrap alert.
                                           // Set from a Workbox callback after
                                           // service worker was successfully installed.
      // -----------------------------------------------------------------------------------------
    };
 
    // Workbox and React component callbacks.
    // We want these bound to this class so that garbage collection
    // never eliminates them while a Workbox event handler might
    // call one of them.
    this.resetSWNotificationStates = this.resetSWNotificationStates.bind(this);
    this.onSWUpdateAvailable = this.onSWUpdateAvailable.bind(this);
    this.onSWInstallSuccess = this.onSWInstallSuccess.bind(this);
 
  }
 
  // -------- Workbox Service Worker event handlers and state management -------
 
  // Callback for our AppUpdateAlert component.
  resetSWNotificationStates() {
 
    // this covers the intended use case
    // of allowing a server worker update to proceed
    // automatically, once the user has been made aware
    // that the update exists, was downloaded in the background
    // and is ready to install.
    if (this.state.updatedSW && this.state.isSWUpdateAvailable) {
      this.state.updatedSW.postMessage({
        type: 'SKIP_WAITING'
      });
    }
 
    // reset the service worker states
    this.setState({ 
      updatedSW: null,
      isSWUpdateAvailable: false,
      wasSWInstalledSuccessfully: false
    });
  }
 
  // Workbox callback for "service worker update ready" event
  onSWUpdateAvailable(registration) {
    if (this.state.isSet && registration) {
      this.setState({
        updatedSW: registration.waiting,
        isSWUpdateAvailable: true,
        wasSWInstalledSuccessfully: false
      });
    }
  }
 
  // Workbox callback for "service worker installation success" event
  onSWInstallSuccess(registration) {
    if (this.state.isSet) {
      this.setState({ 
        updatedSW: registration,
        isSWUpdateAvailable: false,
        wasSWInstalledSuccessfully: true
      });
    } 
  }
 
  // ------------ React Component life cycle methods ------------
  componentDidMount() {
 
    this.resetSWNotificationStates();
    this.setState({
      isSet: true
    });
 
    // Note: I relocated this snippet from index.js
    // in order to add Workbox's two event handlers
    // for onUpdate and onSuccess.
    //*SUN 2022-08-25 12:52:28 set production
    // if (process.env.NODE_ENV === 'production') {
      serviceWorkerRegistration.register({ 
        onUpdate: this.onSWUpdateAvailable,
        onSuccess: this.onSWInstallSuccess,
        onActivated: this.onSWInstallSuccess   // a custom event that I added to Workbox
      });
    //}
  }
   
  render() {
 
    // service worker app update alerts.
    function AppUpdateAlerts(props) {
      const parent = props.parent;
       
      return(
        <React.Fragment>
            {parent.state.isSet &&
              <React.Fragment>
                {parent.state.isSWUpdateAvailable && 
                        <AppUpdateAlert 
                          msg={UPDATE_AVAILABLE_MESSAGE} 
                          callback={parent.resetSWNotificationStates} /> 
                }
                {parent.state.wasSWInstalledSuccessfully && 
                        <AppUpdateAlert 
                          msg={SUCCESSFUL_INSTALL_MESSAGE} 
                          callback={parent.resetSWNotificationStates} /> 
                }
              </React.Fragment>
            }
        </React.Fragment>
      )
    }
 
    return ( 

      <Fragment>
        <BrowserRouter>
        <AppRoute />  
        <AppUpdateAlerts parent={this}/>       
        </BrowserRouter>
      </Fragment>
   
      )
  }
}

// class App extends Component {
//   render() {
//     return (
//  )
//   }
// }



{/* <React.Fragment>
<Head />
<BrowserRouter>
  <div className={"container-fluid p-0 " + this.state.customClass}>
    <Header />
    <AppUpdateAlerts parent={this}/>
    <Routes />
    <Footer />
  </div>
</BrowserRouter>
</React.Fragment> */}


export default App
