import React, { Component, Fragment } from "react";
import { Navbar, Container, Row, Col, Button, Card } from "react-bootstrap";
import AppURL from "../../api/AppURL";
import Product1 from "../../assets/images/product/product1.png";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Link, Redirect } from "react-router-dom";

// -- For Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//-
class Cart extends Component {
  constructor() {
    super();
    this.state = {
      ProductData: [],
      isLoading: "",
      mainDiv: "d-none",
      PageRefreshStatus: false,
      PageRedirectStaus: false,

      confirmBtn: "Submit Order",
      city: "",
      payment: "",
      name: "",
      address: "",
      shipdate: "",
      notes: "",
      message: "",
    };
  }

  componentDidMount() {
    // *SUN 2022-08-10 16:06:39 Avoid Null of this.props.user
    let email = localStorage.getItem("email");
    // console.log(email);
    
    axios
      .get(AppURL.CartList(email))
      .then((response) => {
        this.setState({
          ProductData: response.data,
          isLoading: "d-none",
          mainDiv: " ",
        });
      })
      .catch((error) => {});
  }

  removeItem = (id) => {
    axios
      .get(AppURL.RemoveCartList(id))
      .then((response) => {
        if (response.data === 1) {
          cogoToast.success("Cart Item Remove", { position: "bottom-center" });
          this.setState({ PageRefreshStatus: true });
        } else {
          cogoToast.error("Your Request is not done ! Try Aagain", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Your Request is not done ! Try Aagain", {
          position: "top-right",
        });
      });
  }; // End Remove Item Mehtod

  PageRefresh = () => {
    if (this.state.PageRefreshStatus === true) {
      // let URL = window.location;
      // Sun* 2022-09-24 18:20:57 windows.location is not working in pwa android so, hard code to url
      let URL = '/cart';

      return <Redirect to={URL} />;
    }
  };

  ItemCSReCalc = (id, quantity, price) => {
    axios
      .get(AppURL.CartItemCSReCalc(id, quantity, price))
      .then((response) => {
        if (response.data === 1) {
          cogoToast.success("Item Quantity Updated!", {
            position: "top-right",
          });
          this.setState({ PageRefreshStatus: true });
        } else {
          cogoToast.error("Your Request is not done ! Try Aagain", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Your Request is not done ! Try Aagain", {
          position: "top-right",
        });
      });
  }; // End ItemPlus Mehtod

  ItemPlus = (id, quantity, price) => {
    axios
      .get(AppURL.CartItemPlus(id, quantity, price))
      .then((response) => {
        if (response.data === 1) {
          cogoToast.success("Item Quantity Increased", {
            position: "bottom-center",
          });
          this.setState({ PageRefreshStatus: true });
        } else {
          cogoToast.error("Your Request is not done ! Try Aagain", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Your Request is not done ! Try Aagain", {
          position: "top-right",
        });
      });
  }; // End ItemPlus Mehtod

  ItemMinus = (id, quantity, price) => {
    axios
      .get(AppURL.CartItemMinus(id, quantity, price))
      .then((response) => {
        if (response.data === 1) {
          cogoToast.success("Item Quantity Decreased", {
            position: "bottom-center",
          });
          this.setState({ PageRefreshStatus: true });
        } else {
          cogoToast.error("Your Request is not done ! Try Aagain", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Your Request is not done ! Try Aagain", {
          position: "top-right",
        });
      });
  }; // End ItemMinus Mehtod

  cityOnChange = (event) => {
    let city = event.target.value;
    this.setState({ city: city });
  };

  shipdateOnChange = (event) => {
     let shipdate = event.target.value;
     this.setState({ shipdate: shipdate });
   };

   notesOnChange = (event) => {
     let notes = event.target.value;
     this.setState({ notes: notes });
   };
  paymentMethodOnChange = (event) => {
    let payment = event.target.value;
    this.setState({ payment: payment });
  };

  CSOnChange = (id, price, event) => {
    let quantity = event.target.value;
    this.setState({ quantity: quantity });
    this.ItemCSReCalc(id, quantity, price);
  };

  EAOnChange = (id, factor, price, event) => {
    let quantity = event.target.value;
    let numfactor = factor.match(/\d+(\.\d+)?/)[0];
    let eaquantity = parseFloat(quantity*numfactor).toFixed(2);
    // console.log(eaquantity);
    
    this.setState({ quantity: eaquantity });

    this.ItemCSReCalc(id,eaquantity,price);
  };

  nameOnChange = (event) => {
    let name = event.target.value;
    this.setState({ name: name });
  };

  addressOnChange = (event) => {
    let address = event.target.value;
    this.setState({ address: address });
  };

  confirmOnClick = () => {
    // let city = this.state.city;
    let payment = this.state.payment;
    // let name = this.state.name;
    // let address = this.state.address;
    // * SUN 2022-08-10 16:04:38 -> prevent NULL of  props.user 
    // let email = this.props.user.email;
    // 
    let email = localStorage.getItem("email");

    let shipdate = this.state.shipdate;
    let notes = this.state.notes;

    // SUN * 07/05/22 this.state -> localStorage

    let city = localStorage.getItem("branch");
    let name = localStorage.getItem("company");
    let address = localStorage.getItem("address");

    // Sun 08/07/22 - No Item Found Routine.. 
    // const MyListCheck = this.state.ProductData;
    // console.log(MyListCheck.length);
    // let totalPriceSumCheck = 0;
    // const MyViewCheck = MyListCheck.map((ProductList, i) => {
    //   totalPriceSumCheck = totalPriceSumCheck + parseFloat(ProductList.total_price);
    //   console.log(totalPriceSumCheck);
    // });



    if (city.length === 0) {
      cogoToast.error("Please Select Ship From", { position: "top-right" });
    } else if (this.state.ProductData.length === 0) {
      cogoToast.error("No Item Found In Shopping Cart!", { position: "top-right" });
    } else if (shipdate.length === 0) {
      cogoToast.error("Please Select Your Desired Delivery Date", { position: "bottom-center" });      
    } else if (payment.length === 0) {
      cogoToast.error("Please Select Final Confirmation", { position: "bottom-center" });
    } else if (name.length === 0) {
      cogoToast.error("Please Select Your Ship To", { position: "top-right" });
    } else if (address.length === 0) {
      cogoToast.error("Please Select Your Address", { position: "top-right" });
    } else {
      let invoice = new Date().getTime();
      let MyFromData = new FormData();
    
      // *Sun - 08/06/2022 Ship Date Conversion
      // Wed Aug 03 2022 00:00:00 GMT-0700 (Pacific Daylight Time) -> 08-03-22 Format
      const conDtYear = `${shipdate.getFullYear()}`;
      const conDtMonth = `${shipdate.getMonth()+1}`.padStart(2,"0");
      const conDtDay = `${shipdate.getDate()}`.padStart(2,"0");
      const conDtObj = [conDtMonth,conDtDay,conDtYear].join("/");
        //console.log(conDtObj);
  

      MyFromData.append("city", city);
      MyFromData.append("payment_method", payment);
      MyFromData.append("name", name);
      MyFromData.append("delivery_address", address);
      MyFromData.append("email", email);
      MyFromData.append("invoice_no", invoice);
      MyFromData.append("delivery_charge", "00");
      MyFromData.append("shipdate", conDtObj); //shipdate overwritten by conDtObj
      MyFromData.append("notes", notes);

      axios
        .post(AppURL.CartOrder, MyFromData )
        .then((response) => {
          if (response.data === 1) {
            cogoToast.success("Order Request Received", {
              position: "bottom-center",
            });
            
            // console.log(invoice);
            
            let wfinvoice = 'WF' + invoice;
            let _message = " ";
            
            // *SUN 2022-08-18 13:28:39 Email Receipt To Customer
            //console.log(1);
            
            const customerdata={
              email:localStorage.getItem("email"),
              invoice_no:wfinvoice               
              }
            
            // console.log(customerdata);

            axios.post(AppURL.UserYougetPassword,customerdata).then(response =>{ 
                 //   console.log(response);
                    this.setState({message:response.data.message});
                    _message = "Customer Email Receipt Sent";
                    cogoToast.success(_message,{
                        position: "bottom-center"
                    });
                    
              }).catch(error=>{
                    this.setState({message:error.response.data.message});
                     _message = "Customer Email Receipt Failed";
                    // cogoToast.error(this.state.message,{
                    cogoToast.error(_message,{
                        position: "bottom-center"
                    });
              }); 


            
            // console.log(3);
     
            
            // *SUN 2022-08-18 13:28:39 Email Receipt To Customer
            const sundancedata={
              email:'mike@softwarecare.com',
              invoice_no:wfinvoice               
              }
              
            // console.log(sundancedata);

            axios.post(AppURL.UserYougetPassword,sundancedata).then(response =>{ 
               //     console.log(response);
                    this.setState({message:response.data.message})
                    cogoToast.success(this.state.message,{
                        position: "bottom-center"
                    });
                    
              }).catch(error=>{
                    this.setState({message:error.response.data.message})
                    cogoToast.error(this.state.message,{
                        position: "bottom-center"
                    });
              }); 

            
            this.setState({ PageRedirectStaus: true });

          } else {
            cogoToast.error("Your Request is not done ! Try Aagain", {
              position: "top-right",
            });
          }
        })
        .catch((error) => {
          cogoToast.error("Your Request is not done ! Try Aagain", {
            position: "top-right",
          });
        });
    }
  }; // edn confim order method

  PageRedirect = () => {
    if (this.state.PageRedirectStaus === true) {
      return <Redirect to="/orderlist" />;
    }
  };

  // SUN 2022-09-25 08:11:55 CounterRefreshPage for Mobile Device / Use by Continue Shopping Button 
  // common.css body tag overscroll-behavior-y: contain; 
  CounterRefreshPage = () => {          

    let email = localStorage.getItem('email');
    axios.get(AppURL.CartCount(email)).then((response)=>{
         this.setState({cartCount:response.data})

    })

    window.location.href="/";
    
  }

  render() {
    if (!localStorage.getItem("token")) {
      return <Redirect to="/login" />;
    }

    const MyList = this.state.ProductData;
    let totalPriceSum = 0;
    const MyView = MyList.map((ProductList, i) => {
      // Calculate the row value by adding 1 to the index
      const row = i + 1;

      totalPriceSum = totalPriceSum + parseFloat(ProductList.total_price);
      // totalPriceSum = totalPriceSum+parseInt(ProductList.total_price)

      // *Sun 2022-08-07 16:50:18 Inner Box Calculation
      let numfactor = ProductList.size.match(/\d+(\.\d+)?/)[0];
      let eaInCS = parseFloat(1/numfactor).toFixed(0);
      // console.log(eaInCS);
      return (
        <div>
          <Card>
            <Card.Body>
              <Row>
                <Col md={3} lg={3} sm={6} xs={6}>
                  <p className="btn btn-sm"><i className="fa fa-star"></i> <b>{row} </b></p>
                  <img className="cart-product-img" src={ProductList.image} />
                </Col>

                <Col md={6} lg={6} sm={6} xs={6}>
                  <h5 className="product-name">{ProductList.product_name}</h5>
                  <h6>{ProductList.product_code} | Quantity = {ProductList.quantity} </h6>
                  <p>{ProductList.size} | <font color="green"><b>{eaInCS} EA In CS</b></font></p>
                  <h6>
                    Price = {ProductList.unit_price} x {ProductList.quantity} =
                    $ {ProductList.total_price}
                  </h6>
                </Col>

                <Col md={3} lg={3} sm={12} xs={12}>
                  <Button
                    onClick={() => this.removeItem(ProductList.id)}
                    className="btn mt-2 mx-1 btn-lg site-btn"
                  >
                    <i className="fa fa-trash-alt"></i>{" "}
                  </Button>

                  <Button
                    onClick={() =>
                      this.ItemPlus(
                        ProductList.id,
                        ProductList.quantity,
                        ProductList.unit_price
                      )
                    }
                    className="btn mt-2 mx-1 btn-lg site-btn"
                  >
                    <i className="fa fa-plus"></i>{" "}
                  </Button>

                  <Button
                    onClick={() =>
                      this.ItemMinus(
                        ProductList.id,
                        ProductList.quantity,
                        ProductList.unit_price
                      )
                    }
                    className="btn mt-2 mx-1 btn-lg site-btn"
                  >
                    <i className="fa fa-minus"></i>{" "}
                  </Button>
                  <div
                    className="d-flex justify-content-left"
                    md={6}
                    lg={6}
                    sm={12}
                    xs={12}
                  >
                    <label style={{ fontSize: "2rem" }}>
                      <span style={{ fontWeight: "bold" }}>CS</span>
                    </label>
                    <input
                      style={{ width: "85px" }}
                      onChange={(event) => {
                        this.CSOnChange(
                          ProductList.id,
                          ProductList.unit_price,
                          event
                        );
                      }}
                      className="form-control"
                      type="text"
                      placeholder={ProductList.quantity}
                    />
                    {/*  *SUN 2022-08-07 15:28:54 If Conver Factor is 1, then you can't input the EA quantity.
                     // 
                     // { console.log(ProductList.size)};
                      */}
                    
                    <span style={(ProductList.size === 'Factor: 1.0000') ?  { display: 'none'}: { display: 'show'}}>
              
                    <label style={{ fontSize: "1rem" }}>
                      <span style={{ fontWeight: "bold" }}>EA</span>
                    </label>
                    <input
                      style={{ width: "50px" }}
                      onChange={(event) => {
                          this.EAOnChange(
                          ProductList.id,
                          ProductList.size, 
                          ProductList.unit_price,
                          event
                        );
                      }}
                      className="form-control"
                      type="text"
                      placeholder="EA"
                    />
                    </span>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      );
    });

    return (
      <Fragment>
        <Container fluid={true}>
          <div className="section-title text-center mb-55">
            <h2>Product Cart List</h2>
          </div>

          <div classname="section-title text-center mb-55">
            <center>
            {/* <Link to="/" onClick={this.CounterRefreshPage} className="h4 btn"><font color="blue">CONTINUE SHOPPING</font></Link> */}
            <Link to="#" onClick={() => window.history.back()} className="h4 btn"><font color="blue">CONTINUE SHOPPING</font>
            </Link>

            </center>
          </div>

          <Row>
            <Col className="p-1" lg={7} md={7} sm={12} xs={12}>
              {MyView}
            </Col>

            <Col className="p-1" lg={5} md={5} sm={12} xs={12}>
              <div className="card p-2">
                <div className="card-body">
                  <div className="container-fluid ">
                    <div className="row">
                      <div className="col-md-12 p-1  col-lg-12 col-sm-12 col-12">
                        <h5 className="Product-Name text-danger">
                          Total Amount: $ {totalPriceSum.toFixed(2)}{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      
                    <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                    <label className="form-label text-danger">SELLING PRICE IS SUBJECT TO CHANGE UPON INVOICING.</label>
                    <label className="form-label text-danger">ORDER QUANTITIES ARE SUBJECT TO INVENTORY.</label>
                    <label className="form-label text-danger">CALIFORNIA REDEMPTION VALUE(CRV) IS CALCULATED UPON INVOICING. (California Customer Only)</label>
                      </div>

                      
                      
                      
                      <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                        <label className="form-label">
                          Ship From (H10:LA/H20:SF/H30:TX/H40:NY/H60:LV/H85:AT)
                        </label>
                        <input
                          onChange={this.cityOnChange}
                          value={localStorage.getItem("branch")}
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        {/* <option value="">Choose</option>
     <option value="Dhaka">Assam</option>
     <option value="Dhaka">Bihar </option>
     <option value="Dhaka">Goa </option>
     <option value="Dhaka">Gujarat </option>
     <option value="Dhaka">Himachal Pradesh </option>
     <option value="Dhaka">Punjab  </option>
     </select> */}
                      </div>

                      <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                        <label className="form-label">Ship To</label>
                        <input
                          onChange={this.nameOnChange}
                          value={localStorage.getItem("company")}
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>

                      <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                        <label className="form-label">Delivery Address</label>
                        <textarea
                          onChange={this.addressOnChange}
                          value={localStorage.getItem("address")}
                          rows={2}
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                      <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                         <label className="form-label">Desired Ship Date</label>
                         <DatePicker className="form-control" 
                           selected={this.state.shipdate} 
                           onChange={date=> {
                              this.setState({shipdate:date})
                           }
                         }
                         placeholderText="Please select your desired delivery date">
                         

                       </DatePicker>

                       </div>                    

                       <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
     <label className="form-label">Notes</label>
     <textarea onChange={this.notesOnChange}   rows={4}  className="form-control" type="text" placeholder="You can enter notes about order or unlisted items what you need."/>
                     </div>                                         
                      <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                        <label className="form-label">
                        </label>
                        <select
                          onChange={this.paymentMethodOnChange}
                          className="form-control"
                        >
                          <option value="Final Confirmation">Final Confirmation</option>
                          <option value="CompleteOrder">
                          Order Confirmed. Please SUBMIT! &#128076;
                          </option>
                        </select>
                      </div>
                      <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                        <button
                          onClick={this.confirmOnClick}
                          className="btn  site-btn"
                        >
                          {" "}
                          {this.state.confirmBtn}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {this.PageRefresh()}

        {this.PageRedirect()}
      </Fragment>
    );
  }
}

export default Cart;
