import React, { Component, Fragment } from 'react'
import AppURL from '../api/AppURL'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import Categories from '../components/home/Categories'
// import Collection from '../components/home/Collection'
// import FeaturedProducts from '../components/home/FeaturedProducts'
import HomeTop from '../components/home/HomeTop'
import HomeTopMobile from '../components/home/HomeTopMobile'
import NewArrival from '../components/home/NewArrival'
import axios from 'axios'
import cogoToast from 'cogo-toast';
import { Redirect } from 'react-router-dom';

export class HomePage extends Component {

     componentDidMount(){
          window.scroll(0,0);
          
          if(!localStorage.getItem('token')){
               cogoToast.warn('Please You have to Login First',{position:'top-right'});
            }
            
          // alert();          
              
          this.GetVisitorDetails();

     }

     GetVisitorDetails =()=>{
          axios.get(AppURL.VisitorDetails).then().catch()
     }


     render() { 
          
          if(!localStorage.getItem('token')){
               // time wait for redirect to login page
               setTimeout(() =>{
                    return <Redirect to="/" />
               }, 10000);
          }
          
     
          return (   
               <Fragment> 
                    <div className="Desktop">
               <NavMenuDesktop />
               <HomeTop />
                    </div>

                    <div className="Mobile">
                    <NavMenuMobile />  
                    <HomeTopMobile />
                    </div>                       
                    <Categories />                    
                    {/* <FeaturedProducts />
                    <NewArrival />

                    <Collection />
                     */}
                    <div className="Desktop">
                    <FooterDesktop/>
                    </div>

                    <div className="Mobile">
                    <FooterMobile/>
                    </div>
                    
               </Fragment>
          )
     }
}

export default HomePage
